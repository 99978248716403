/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { TextArea } from 'App/Components/Common/TextArea'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSpinner,
  // faEdit,
  faTrash,
  faCaretUp,
} from '@fortawesome/free-solid-svg-icons'
import { useMutation, useQuery } from 'react-query'
import { ButtonSecondary, StyledButton } from 'App/Styled'
// import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { TimeAgo } from 'App/Components/Common/TimeAgo'
import { Reload } from 'App/Components/Common/Reload'
import { AxiosInstance, AMS_BACKEND_API, offset } from 'App/Config'
import { useSelector } from 'react-redux'
import { permissionGranted } from 'App/Services'
import { NotificationManager } from 'react-notifications'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { SearchInputField } from '../SearchInputField'
import searchQueryParser from 'App/Services/General/searchQueryParser'

export const Notes = ({ resourceId, apiType }) => {
  const [note, setNote] = useState(``)
  const [activeNote, setActiveNote] = useState(null)
  // const [notification, setNotification] = useState(false)
  // const [editableNote, setEditableNote] = useState(null)
  const [editing, setEditing] = useState(``)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState(``)
  const [customSearching, setCustomSearching] = useState(false)

  const { sub: authorized } = useSelector(({ user }) => user?.user?.profile)
  const [errors, setErrors] = useState({
    formSubmit: false,
    noteError: ``,
  })

  const {
    data: notesData,
    refetch: getAllNotes,
    isLoading,
    isFetching,
    error,
  } = useQuery(
    `${apiType}NoteApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/notes/${resourceId}?${
          searchQuery.length ? `search=${searchQueryParser(searchQuery)}&` : ``
        }model=${apiType}&sort=""&page=${currentPage}&offset=${offset}`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setCustomSearching(false)
      },
    },
  )

  //  add note
  const { mutate: addNote, isLoading: addingNote } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/notes/${resourceId}`, {
        ...payload,
      }).then((res) => {
        setNote(``)
        NotificationManager.success(`Note Created successfully`)
        res.data && getAllNotes()
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  //edit Note
  // const { mutate: updateNote, isLoading: updatingNote } = useMutation(
  //   async (payload) =>
  //     await AxiosInstance.patch(
  //       `${AMS_BACKEND_API}/api/notes/${resourceId}/note/${payload.id}`,
  //       {
  //         note: payload.note,
  //         model: apiType,
  //       },
  //     ).then((res) => {
  //       if (!res.hasError) {
  //         setEditing(false)
  //         NotificationManager.success(`Note Updated successfully`)
  //         setNote(``)
  //         res.data && getAllNotes()
  //       } else {
  //         setNotification(true)
  //       }
  //     }),
  //   {
  //     refetchOnWindowFocus: false,
  //     enabled: false,
  //   },
  // )
  // delete Note
  const { mutate: deleteNote, isLoading: deletingNote } = useMutation(
    async (payload) =>
      await AxiosInstance.delete(
        `${AMS_BACKEND_API}/api/notes/${apiType}/${resourceId}/note/${payload}`,
      ).then((res) => {
        NotificationManager.success(`Note Deleted successfuly`)
        setNote(``)
        res.data && getAllNotes()
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const validateForm = () => {
    setSearchQuery(``)
    if (!note.length) {
      handleErrors(`noteError`, `Enter Note`)
    } else if (note.length && note.length > 150) {
      handleErrors(`noteError`, `Note length must be less than 150 characters`)
    } else {
      handleErrors(`noteError`, ``)
    }
    handleErrors(`formSubmit`, true)
  }

  const resetNote = () => {
    setEditing(false)
    setNote(``)
  }

  useEffect(() => {
    if (errors.formSubmit && !errors.noteError.length) {
      // if (editing) {
      //   updateNote({
      //     id: editableNote._id,
      //     note,
      //   })
      // } else {
      addNote({ note, model: apiType })
      // }
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  useEffect(() => {
    if (!customSearching) getAllNotes()
  }, [currentPage])

  return (
    <div className="mt-2 h-420 overflow-x-hidden overflow-y-auto hide-scroller hide-scroller-firefox">
      {/* {notification && (
        <ReactNotification action="error" message="Something went wrong" />
      )} */}
      {permissionGranted([
        `notes:create`,
        `corporate-manager:*`,
        `manager:*`,
        `admin:*`,
      ]) && (
        <>
          <TextArea
            id="note"
            rows={4}
            value={note}
            placeholder="Add Note"
            errorMessage={errors.noteError}
            setValue={(e) => setNote(e.target.value)}
          />

          <div
            className={`w-full flex ${
              notesData?.notes?.length > 0 ? `justify-between` : `justify-end`
            }`}
          >
            <div className="flex-1 px-1 mt-5">
              <SearchInputField
                type="text"
                placeholder="Search Note"
                value={searchQuery}
                onEnter={(e) => {
                  if (e.key === `Enter`) {
                    setCurrentPage(1)
                    setCustomSearching(true)
                    getAllNotes()
                  }
                }}
                setValue={(e) => {
                  setSearchQuery(e.target.value)
                }}
                disabled={isFetching}
              />
            </div>
            <span className="flex-1 items-center">
              <div className="flex justify-end">
                <StyledButton onClick={validateForm}>
                  <div className="flex gap-x-2 text-white">
                    {editing ? (
                      <span>Update Note </span>
                    ) : (
                      <span>Add Note </span>
                    )}
                    {addingNote && (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        spin={true}
                        className="my-auto"
                      />
                    )}
                  </div>
                </StyledButton>
                {/* </span> */}
                {/* <span> */}
                <ButtonSecondary onClick={() => resetNote()}>
                  <span>Cancel</span>
                </ButtonSecondary>
              </div>
            </span>
          </div>

          <div className="px-4 py-2">
            <ReactPagination
              offset={offset}
              currentPage={currentPage}
              totalPages={notesData?.totalPages}
              totalRecords={notesData?.totalRecords}
              curerntPageRecords={notesData?.currentPageRecords}
              onChange={(page) => setCurrentPage(page)}
            />
          </div>
        </>
      )}
      {isLoading || isFetching ? (
        <div className="text-center py-10">
          <FontAwesomeIcon
            icon={faSpinner}
            spin={true}
            size="1x"
            className="text-red-500"
          />
        </div>
      ) : error ? (
        <Reload refetch={() => getAllNotes()} />
      ) : notesData?.notes?.length > 0 ? (
        notesData?.notes?.map((note, index) => {
          const editedIndex = activeNote === index
          let isAuthorized = note?._creator?.value === authorized ? true : false
          return (
            <div className="flex flex-col mt-4 rounded-xl" key={index}>
              <div className="flex flex-row">
                <div className="flex flex-row  items-center justify-center">
                  <span className="font-semibold text-indigo-500 text-sm">
                    {note._creator?.label}
                  </span>
                  {/* <span
                    className={`py-0.5 px-1 mx-1 flex-shrink text-sm rounded-md text-white bg-indigo-500`}
                  >
                    Roles
                  </span> */}
                  <span className=" mx-1 text-xs flex items-center">
                    <TimeAgo timeStamp={note.createdAt} />
                  </span>
                </div>
              </div>

              <div className="relative  mt-2 p-4 rounded-sm  shadow-sm  px-4 flex flex-row justify-between flex-wrap text-gray-600 bg-blue-50 dark:text-white">
                <FontAwesomeIcon
                  className=" absolute  text-blue-50 -top-5 left-0"
                  size="2x"
                  icon={faCaretUp}
                />
                <div>
                  <span className="font-bold text-indigo-500 text-lg">“</span>
                  {note.note}
                  <span className="font-bold text-indigo-500 text-lg">”</span>
                </div>
                <div
                  onClick={() => setActiveNote(index)}
                  className="cursor-pointer"
                >
                  {isAuthorized && (
                    <div className="flex float-right">
                      {/* {permissionGranted([
                        `notes:update`,
                        `corporate-manager:*`,
                        `manager:*`,
                        `admin:*`,
                      ]) && (
                        <span data-tip="Edit" className="tooltip">
                          <FontAwesomeIcon
                            className=" mx-1 transform transition  hover:text-blue-600  hover:-translate-y-0 hover:scale-105  cursor-pointer "
                            icon={faEdit}
                            onClick={() => {
                              handleErrors(`formSubmit`, false)
                              setEditing(true)
                              setNote(note.note)
                              setEditableNote(note)
                            }}
                          />
                        </span>
                      )} */}
                      {/* {permissionGranted([
                        `notes:delete`,
                        `corporate-manager:*`,
                        `manager:*`,
                        `admin:*`,
                      ]) && (
                        <span data-tip="Delete" className="tooltip">
                          <FontAwesomeIcon
                            className="mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 
                        hover:scale-105 
                        cursor-pointer "
                            icon={
                              editedIndex && deletingNote ? faSpinner : faTrash
                            }
                            spin={editedIndex && deletingNote}
                            size="1x"
                            onClick={() => deleteNote(note._id)}
                          />
                        </span>
                      )} */}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        })
      ) : (
        <div className="text-center my-6 text-sm text-gray-400 font-bold">
          No Notes Found
        </div>
      )}
    </div>
  )
}
