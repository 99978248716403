import React, { useEffect, useState } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { Container, StyledButton, PageHeader } from 'App/Styled'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
} from '@windmill/react-ui'

import { Reload } from 'App/Components/Common/Reload'
import { useDebouncedCallback } from 'use-debounce/lib'
import { Checkbox } from 'App/Components/Common/Checkbox'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { loadOffset } from 'App/Services/General/loadOffset'

import { AxiosInstance, offset, AMS_BACKEND_API } from 'App/Config'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useRouteMatch } from 'react-router-dom'
import {
  faChevronCircleDown,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons'
import { NotificationManager } from 'react-notifications'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { permissionGranted } from 'App/Services'
import dateFormat from 'App/Services/General/dateFormat'
import searchQueryParser from 'App/Services/General/searchQueryParser'
import { STATUS } from 'App/constants'

const tableColumns = [
  { label: `CONTACT INFO`, value: `contactInfo` },
  { label: `BODY`, value: `body` },
  { label: `STATUS`, value: `status` },
  { label: `UPDATED AT`, value: `updateDate` },
]

export const AllCampaignGroupDetails = () => {
  const {
    url,
    params: { campaignid },
  } = useRouteMatch()

  const history = useHistory()
  const [stateChecked, setStateChecked] = useState({
    allChecked: false,
    list: [],
    selected: [],
  })

  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  const [isOffset, setIsOffset] = useState(``)
  const [collapseIndex, setCollapseIndex] = useState(``)
  const [active, setActive] = useState(false)
  //  sorting and filtering states
  const [sort, setSort] = useState(``)

  const [searchQuery, setSearchQuery] = useState(``)

  const [quoteUpdated, setQuoteUpdated] = useState(false)

  // fetching all frenchises
  const {
    isLoading: gettingTemplate,
    data: campaignGroupDetailData,
    error: camapignError,
    refetch: getAllCampaignDetails,
    isFetching: campaignFetching,
  } = useQuery(
    `broadcastingCampaignDetail`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/broadcasts/${campaignid}/logs?${
          searchQuery.length ? `search=${searchQueryParser(searchQuery)}&` : ``
        }sort="${sort?.length > 0 ? sort : ``}"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res?.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setCustomSearching(false)
      },
    },
  )

  const {
    error,
    isLoading,
    mutate: retryCampaignMutate,
  } = useMutation(
    async (payload) =>
      campaignGroupDetailData?.data?.length
        ? await AxiosInstance.post(
            `${AMS_BACKEND_API}/api/broadcasts/retry?scope=${campaignGroupDetailData?.data[0]?.channel}`,
            {
              ...payload,
            },
          ).then((res) => {
            if (!res.hasError) {
              NotificationManager.success(`Retry Initialized`)
            }
          })
        : null,
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: () => {
        getAllCampaignDetails()
      },
    },
  )

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }

  useEffect(() => {
    if (!customSearching) {
      getAllCampaignDetails()
    }
  }, [sort, currentPage, isOffset])

  useEffect(() => {
    if (quoteUpdated) {
      getAllCampaignDetails()
      setQuoteUpdated(false)
    }
  }, [quoteUpdated])

  const callAgentsQuery = useDebouncedCallback(() => {
    getAllCampaignDetails()
  }, 700)

  const handleAllBoxes = (e) => {
    e.stopPropagation()
    let itemName = e.target.name
    let checked = e.target.checked

    setStateChecked((prevState) => {
      let { list, allChecked, selected } = prevState
      if (itemName === `checkAll`) {
        allChecked = checked
        list = list.map((item) => ({ ...item, isChecked: checked }))
        if (checked) {
          selected = list
        } else {
          selected = []
        }
      } else {
        list = list.map((item) =>
          item.name === itemName ? { ...item, isChecked: checked } : item,
        )
        allChecked = list.every((item) => item.isChecked)
        selected = list.filter((trueItems) => trueItems.isChecked)
      }
      return { list, allChecked, selected }
    })
  }
  useEffect(() => {
    if (stateChecked?.list?.length <= 0) {
      campaignGroupDetailData?.data?.map((eachBox) => {
        setStateChecked((preState) => ({
          ...preState,
          list: [
            ...preState.list,
            {
              id: eachBox._id,
              name: eachBox._id,
              isChecked: false,
              mId: eachBox._id,
            },
          ],
        }))
      })
    }
  }, [campaignGroupDetailData])

  const handleCollapse = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    if (collapseIndex !== id) {
      setCollapseIndex(id)
    } else {
      setCollapseIndex(``)
    }
  }
  function stripHtmlTags(html) {
    const tmpElement = document.createElement(`div`)
    tmpElement.innerHTML = html
    return tmpElement.textContent || tmpElement.innerText || ``
  }
  return (
    <Container>
      <PageHeader>
        <div>Campaign Details</div>

        <div className="flex items-center ">
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>

          <div className="text-end ">
            <div className="flex">
              <SearchInputField
                type="text"
                placeholder="Type here..."
                value={searchQuery}
                setValue={(e) => {
                  setSearchQuery(e.target.value)
                  setCurrentPage(1)
                  setCustomSearching(true)
                  callAgentsQuery()
                }}
                disabled={campaignFetching}
              />
            </div>
          </div>
        </div>
      </PageHeader>
      <div className="flex justify-end items-center p-2">
        {permissionGranted([
          `broadcasts:update`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]) && (
          <StyledButton
            onClick={() =>
              retryCampaignMutate({
                broadcastId: campaignid,
                logs: stateChecked?.selected.map((logs) => logs.id),
              })
            }
            disabled={!stateChecked?.selected?.length}
          >
            <div className="flex gap-2 text-white">
              <span>Retry </span>
            </div>
          </StyledButton>
        )}
      </div>

      {/* filters */}
      <>
        <Spinner loading={gettingTemplate || isLoading} />
        <div
          className={`bg-white mb-1 sm:mb-0 justify-center items-center px-2 ${
            (gettingTemplate || campaignFetching || isLoading) &&
            ` opacity-30 pointer-events-none`
          }`}
        >
          {camapignError || error ? (
            <Reload refetch={() => getAllCampaignDetails()} />
          ) : (
            <>
              <div className="overflow-x-auto  rounded-t-md">
                <table className="w-full border-collapse">
                  <TableHeader>
                    <TableRow>
                      <TableCell className="text-center">
                        <div className="flex-1">
                          <Checkbox
                            name="checkAll"
                            setValue={(e) => handleAllBoxes(e)}
                          />
                        </div>
                      </TableCell>
                      {tableColumns.map((item, idx) => {
                        return (
                          <TableCell key={idx}>
                            <div className="cursor-pointer select-none text-sm flex truncate">
                              <div
                                onClick={() => {
                                  handleSorting(item.value)
                                }}
                                className={`flex ${
                                  item.value === sort ? `text-red-500` : ``
                                }`}
                              >
                                {item.label}
                              </div>
                              <div
                                className="ml-2 px-1 rounded-sm cursor-pointer flex"
                                onClick={() => handleSorting(`-` + item.value)}
                              >
                                <FontAwesomeIcon
                                  icon={
                                    sort.includes(`-`) &&
                                    sort == `-` + item.value
                                      ? faSortUp
                                      : faSortDown
                                  }
                                  size="lg"
                                  className={`${
                                    sort.includes(`-`) &&
                                    sort == `-` + item.value
                                      ? `text-red-500`
                                      : `-mt-1.5`
                                  }`}
                                />
                              </div>
                            </div>
                          </TableCell>
                        )
                      })}
                      <TableCell className="select-none text-sm float-right mt-2">
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHeader>

                  <TableBody>
                    {campaignGroupDetailData?.data?.length != 0 ? (
                      campaignGroupDetailData?.data?.map((template, index) => (
                        <React.Fragment key={index}>
                          <TableRow
                            key={index}
                            className="hover:bg-gray-100 whitespace-nowrap cursor-pointer"
                            onClick={() => {
                              history.push(`${url}/${template?._id}`)
                            }}
                          >
                            <td
                              className="px-4 py-0.5"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <Checkbox
                                name={template?._id}
                                value={stateChecked?.list[index]?.isChecked}
                                setValue={(e) => handleAllBoxes(e)}
                              />
                            </td>
                            <td className="px-4 py-0.5">
                              {template?.contactInfo ? (
                                template?.contactInfo
                              ) : (
                                <Badge>N/A </Badge>
                              )}
                            </td>
                            <td className="px-4 py-0.5 max-w-sm truncate">
                              {template?.body ? (
                                template?.channel === `MAIL` ? (
                                  stripHtmlTags(template?.body)
                                ) : (
                                  template?.body
                                )
                              ) : (
                                <Badge>N/A </Badge>
                              )}
                            </td>
                            <td className="px-4 py-0.5 truncate">
                              {template?.status ? (
                                <Badge
                                  type={`${
                                    template?.status == STATUS.DELIVERED
                                      ? `success`
                                      : template?.status == STATUS.FAILED
                                      ? `danger`
                                      : `primary`
                                  }`}
                                >
                                  {template?.status}
                                </Badge>
                              ) : (
                                <Badge>N/A </Badge>
                              )}
                            </td>
                            <td className="px-4 py-0.5 truncate">
                              {template?.updatedAt ? (
                                dateFormat(template?.updatedAt)
                              ) : (
                                <Badge>N/A</Badge>
                              )}
                            </td>

                            {/* Actions TD */}
                            <td className="px-8 py-0.5 z-0 text-right text-gray-400">
                              {template?.body?.length > 45 && (
                                <span data-tip="Show" className="tooltip">
                                  <FontAwesomeIcon
                                    className={` transform transition duration-100 mx-1 hover:text-blue-500  hover:-translate-y-0 hover:scale-105  cursor-pointer  ${
                                      collapseIndex === index &&
                                      `rotate-180 text-blue-600`
                                    }`}
                                    icon={faChevronCircleDown}
                                    onClick={(e) => {
                                      setActive(!active)
                                      handleCollapse(index, e)
                                    }}
                                  />
                                </span>
                              )}
                            </td>
                          </TableRow>
                          {collapseIndex === index && (
                            <TableRow>
                              <td></td>
                              <td></td>
                              <td className="px-4 py-0.5 ">
                                {template?.body ? (
                                  template?.channel === `MAIL` ? (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: template?.body,
                                      }}
                                    ></div>
                                  ) : (
                                    template?.body
                                  )
                                ) : (
                                  <Badge>N/A </Badge>
                                )}
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </TableRow>
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <td colSpan="12">
                        <div className="w-full text-center p-6  ">
                          <p>No Campaign Found</p>
                        </div>
                      </td>
                    )}
                  </TableBody>
                </table>
              </div>
              <div className="px-4 py-2">
                <ReactPagination
                  offset={isOffset?.value ? isOffset?.value : offset}
                  currentPage={currentPage}
                  totalPages={campaignGroupDetailData?.totalPages}
                  totalRecords={campaignGroupDetailData?.totalRecords}
                  curerntPageRecords={
                    campaignGroupDetailData?.currentPageRecords
                  }
                  onChange={(page) => setCurrentPage(page)}
                />
              </div>
            </>
          )}
        </div>
      </>
    </Container>
  )
}
