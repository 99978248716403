export const DATE_FORMATS = {
    DATE_ONLY: `YYYY-MM-DD`,
    DATE_TIME: `YYYY-MM-DD hh:mmA`,
    DATE_TIME_ALT: `MM-DD-YYYY hh:mmA`,
    DATE_TIME_SECONDS: `YYYY-MM-DD hh:mm:ss`
};

export const INSURANCE_TYPE = {
    PERSONAL_AUTO: `PERSONAL_AUTO`,
    LIFE: `LIFE`,
}

export const AGENT_ACCOUNT_STATUS = {
    ACTIVE: `ACTIVE`,
    INACTIVE: `INACTIVE`,
    INVITED: `INVITED`,
}

export const STATUS = {
    COMPLETED: `COMPLETED`,
    PENDING: `PENDING`,
    EXPIRED: `EXPIRED`,
    CANCELED: `CANCELED`,
    APPROVED: `APPROVED`,
    SKIPPED: `SKIPPED`,
    DELIVERED: `DELIVERED`,
    FAILED: `FAILED`
}

export const ACTIVITY_TABS_NAME = {
    NOTES: `Notes`,
    LOGS: `Logs`,
    CUSTOMER_COMMUNICATIONS: `Customer Communications`,
    MEDIA: `Media`,
}

export const ACTIVITY_TABS = {
    NOTES_AND_LOGS_TABS: [
        {
            name: ACTIVITY_TABS_NAME.NOTES,
            number: 1,
        },
        {
            name: ACTIVITY_TABS_NAME.LOGS,
            number: 2,
        },
    ],
    LOGS_TAB: [
        {
            name: ACTIVITY_TABS_NAME.LOGS,
            number: 1,
        },
    ],
}

export const POLICY_TERMS = {
    '1': `Monthly`,
    '12': `Annual`,
    '3': `Quarterly`,
    '6': `Semi Annual`,
    '2': `Two Month`,
}

export const QUOTE_PAYMENT_OPTIONS = {
    'INSTALLMENTS': `Installments`,
    'INSTALLMENT EFT': `Installments EFT`,
    'FULL': `Paid in Full`,
    'FULL EFT': `Paid in Full EFT`,
}

export const QUOTE_STATUSES = {
    DRAFT: `DRAFT`,
    QUOTE: `QUOTE`,
    SOLD: `SOLD`,
    NOT_ANSWERED: `NOT ANSWERED`,
    LOST: `LOST`,
    IN_PROGRESS: `IN PROGRESS`,
    MANUAL: `MANUAL`,
    LEAD: `LEAD`,
    POLICY: `POLICY`,
    INCOMPLETE: `INCOMPLETE`
}

//Payment types in 'Customers' section
export const PAYMENT_TYPES = {
    INSTANT: `INSTANT`,
    SCHEDULED: `SCHEDULED`

}

export const TIME_PERIODS = {
    DAY: `DAY`,
    WEEK: `WEEK`,
    MONTH: `MONTH`,
    YEAR: `YEAR`,
};

export const POLICY_CODES = {
    BI: `BI`,
    PD: `PD`,
    PIP: `PIP`,
    UMPD: `UMPD`,
    CWAIV: `CWAIV`
}
export const STATES = {
    CO: `CO`,
    FL: `FL`,
    TX: `TX`,
    CA: `CA`,
    UT: `UT`,
}

export const GRADIENT_ARRAY = [
    `gradient-1`,
    `gradient-2`,
    `gradient-3`,
    `gradient-4`,
    `gradient-1`,
    `gradient-2`,
    `gradient-3`,
    `gradient-4`,
    `gradient-1`,
    `gradient-2`,
    `gradient-3`,
    `gradient-4`,
    `gradient-5`,
];

export const PREMIUM_FEE_TYPE = [
    `NB EFT TO COMPANY`,
    `END HOMEOWNER PREM`,
    `NB HOMEOWNER PREMIUM`,
    `PREMIUM TO ROBERT MKORENO`,
    `END COMMERCIAL EFT`,
    `MP EFT TO COMPANY`,
    `MP HOMEOWNER PREMIUM`,
    `Other`,
    `MP COMMERCIAL EFT`,
    `NB COMMERCIAL CHECK TO CO`,
    `END EFT TO COMPANY`,
    `RENEWAL PREMIUM`,
    `END COMMERCIAL CHECK TO CO`,
    `INSURED REFUND PREMIUM`,
    `NB COMMERCIAL EFT`,
    `RE-INSTATE PREMIUM`,
]

export const FILE_MANAGER_CONSTANTS = {
    SET_DIRECTORIES: `SET_DIRECTORIES`,
    SELECTED_DIRECTORY: `SELECTED_DIRECTORY`,
    ACTIVE_DIRECTORY: `ACTIVE_DIRECTORY`,
    SELECTED_FILE: `SELECTED_FILE`,
    SET_CUSTOMER_NAME: `SET_CUSTOMER_NAME`,
    SET_SEARCH_RESULTS: `SET_SEARCH_RESULTS`,
    SET_FETCHING_DIR: `SET_FETCHING_DIR`
}

export const ACTION_TYPES = {
    CREATE_DIRECTORY: `CREATE_DIRECTORY`,
    UPLOAD_FILE: `UPLOAD_FILE`,
    EDIT_DESC: `EDIT_DESC`,
    DELETE: `DELETE`,
    RENAME: `RENAME`,
    COPY_TO: `COPY_TO`,
    MOVE_TO: `MOVE_TO`
};


export const ENTITY_TYPES = {
    FILE: `FILE`,
    DIRECTORY: `DIRECTORY`
};

export const FILE_ACTION_TYPES = {
    COPY: `copy`,
    MOVE: `move`,
};

export const FILE_TYPES = {
    PDF: `pdf`,
    JPEG: `jpeg`,
    JPG: `jpg`,
    PNG: `png`
}

export const getLink = (link) => {
    let newLink =
        link?.startsWith(`https://veronicas-ams-pp.s3.us-west-1.amazonaws.com`) ? // Dev S3
            link?.replace(`https://veronicas-ams-pp.s3.us-west-1.amazonaws.com`, ``) :
            link?.startsWith(`https://veronicas-prod.s3.us-west-1.amazonaws.com`) ? //Prod S3
                link?.replace(`https://veronicas-prod.s3.us-west-1.amazonaws.com`, ``) :
                ``
    return newLink
}
