import {
  mainDashboard,
  admin,
  systemRole,
  franchise,
  location,
  agents,
  carriers,
  transaction,
  transectionHistory,
  customers,
  broadcasating,
  campaign,
  template,
  reports,
  ledger,
  rater,
  qoutes,
  quickEstimate,
  prefrences,
  qouteForm,
  crm,
  leads,
  data,
  carDealer,
  credentials,
  transactionInspection,
  policyReport,
  monthlyGoals,
  designation,
  paymentTemplate,
  claim,
  ticket,
  taskManager,
  category,
  generateTicket,
  notification
} from 'App/Assets'
export const SIDEBAR_ITEMS = [
  {
    icon: mainDashboard,
    title: `Dashboard`,
    name: `dashboard`,
    path: `/dashboard`,
    exact: true,
    permission: [`dashboard:read`],
  },
  {
    icon: admin,
    title: `Administration`,
    name: `admin`,
    submenu: [
      {
        icon: systemRole,
        title: `System Roles`,
        path: `/admin/system-roles`,
        permission: [
          `system-roles:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ],
      },
      {
        icon: franchise,
        title: `Franchises`,
        path: `/admin/franchises`,
        permission: [`corporate-manager:*`],
      },
      {
        icon: location,
        title: `Locations`,
        path: `/admin/locations`,
        permission: [`locations:read`, `corporate-manager:*`, `manager:*`],
      },

      {
        icon: agents,
        title: `Agents`,
        path: `/admin/agents`,
        permission: [
          `users:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ],
      },
      // {
      //   icon: carriers,
      //   title: `Carriers`,
      //   path: `/admin/carriers`,
      //   permission: [`carriers:read`],
      // },
      {
        icon: credentials,
        title: `Credentials`,
        path: `/admin/credentials`,
        permission: [
          `credentials:read`,
          `credential-master:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ],
      },
      {
        icon: carriers,
        title: `Carriers`,
        path: `/carriers`,
        permission: [`carriers:read`, `credential-master:*`],
      },
      {
        icon: carriers,
        title: `Imported Carriers`,
        path: `/imported-carriers`,
        permission: [`carriers:read`, `credential-master:*`],
      },
      {
        icon: credentials,
        title: `Group Credentials`,
        path: `/admin/group-credentials`,
        permission: [
          `credentials-group:read`,
          `credentials-group:*`,
          `credential-master:*`,
        ],
      },
      {
        icon: credentials,
        title: `Scraping Credentials`,
        path: `/admin/scarping-credentials`,
        permission: [
          `credentials-group:read`,
          `credentials-group:*`,
          `credential-master:*`,
        ],
      },
      {
        icon: monthlyGoals,
        title: `Goals`,
        path: `/admin/monthlygoals`,
        permission: [
          `monthly-goals:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ],
      },
      {
        icon: designation,
        title: `Designation`,
        path: `/admin/designation`,
        permission: [
          `designation:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ],
      },
      {
        icon: paymentTemplate,
        title: `Payment Templates`,
        path: `/admin/paymenttemplates`,
        permission: [
          `payments-templates:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ],
      },
      {
        icon: notification,
        title: `General Notifications`,
        path: `/admin/general-notifications`,
        permission: [
          `super-admin:*`,
        ],
      },
      {
        icon: claim,
        title: `Claims`,
        path: `/admin/claims`,
        permission: [
          `claims:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ],
      },
      {
        icon: claim,
        title: `Communication Logs`,
        path: `/admin/cs-logs`,
        permission: [
          `super-admin:*`
        ],
      },
    ],
  },

  {
    icon: systemRole,
    title: `Assessment`,
    name: `assessment`,
    submenu: [
      {
        icon: mainDashboard,
        title: `Dashboard`,
        path: `/assessment/analytics`,
        permission: [
          `evaluate:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ],
      },
      {
        icon: customers,
        title: `Team`,
        path: `/assessment/teams`,

        permission: [
          `assessment-team:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ],
      },
      {
        icon: template,
        title: `Template`,
        path: `/assessment/template`,
        permission: [
          `assessment-template:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ],
      },
      {
        icon: reports,
        title: `Assessment Records`,
        path: `/assessment/weeks`,

        permission: [
          `evaluate:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ],
      },
    ],
  },

  {
    icon: transaction,
    title: `Monthly Payments`,
    name: `transaction`,
    submenu: [
      {
        icon: transactionInspection,
        title: `Check`,
        path: `/transaction/inspection`,
        permission: [
          `monthly-payments:*`,
          `call-center-user:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ],
      },
      {
        icon: transectionHistory,
        title: `History`,
        path: `/transaction/transactions-history`,
        permission: [
          `monthly-payments:read`,
          `call-center-user:*`,
          `monthly-payments:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ],
      },
    ],
  },
  // {
  //   icon: carriers,
  //   title: `Carrier`,
  //   name: `carriers`,
  //   submenu: [

  //     {
  //       icon: carriers,
  //       title: `Group Carriers`,
  //       path: `/group-carriers`,
  //       permission: [`carriers-group:read`],
  //     },
  //   ],
  // },

  {
    icon: customers,
    title: `Customers`,
    name: `/customers`,
    path: `/customers`,
    permission: [
      `customers:read`,
      `call-center-user:*`,
      `corporate-manager:*`,
      `manager:*`,
      `admin:*`,
    ],
  },
  {
    icon: customers,
    title: `Policies`,
    name: `/policy_`,
    path: `/policy`,
    permission: [
      `policies:read`,
      `call-center-user:*`,
      `corporate-manager:*`,
      `manager:*`,
      `admin:*`,
    ],
  },
  {
    icon: customers,
    title: `Policies Requests`,
    name: `/policies/requests`,
    path: `/policies/requests`,
    permission: [
      `policies:read`,
      `call-center-user:*`,
      `corporate-manager:*`,
      `manager:*`,
      `admin:*`,
    ],
  },
  {
    icon: broadcasating,
    title: `Broadcasts`,
    name: `broadcasts`,
    submenu: [
      {
        icon: template,
        title: `Templates`,
        path: `/broadcasts/templates`,
        permission: [
          `broadcast-templates:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ],
      },
      {
        icon: campaign,
        title: `Campaigns`,
        path: `/broadcasts/campaigns`,
        permission: [
          `broadcasts:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ],
      },
    ],
  },
  {
    icon: reports,
    title: `Reports`,
    name: `report`,
    submenu: [
      {
        icon: ledger,
        title: `Daily Ledger`,
        path: `/reports/daily-ledger`,
        permission: [
          `accountant-master:*`,
          `accountant:*`,
          `corporate-manager:*`,
          `manager:*`,
        ],
      },
      {
        icon: policyReport,
        title: `Policy Reports`,
        path: `/reports/policy-reports`,
        permission: [`accountant-master:*`],
      },
      {
        icon: policyReport,
        title: `Payment Records`,
        path: `/reports/payment-records`,
        permission: [`accountant-master:*`],
      },
    ],
  },

  {
    icon: rater,
    title: `Rater`,
    name: `raters`,
    submenu: [
      {
        icon: qoutes,
        title: `My Quotes`,
        path: `/raters/myquotes`,
        permission: [
          `quotes:read`,
          `call-center-user:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ],
      },
      {
        icon: quickEstimate,
        title: `Quick Estimate`,
        path: `/raters/quickestimate`,
        permission: [
          `quotes-estimate:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ],
      },
      {
        icon: prefrences,
        title: `Preferences`,
        path: `/raters/preferences`,
        permission: [
          `quotes:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ],
      },
      {
        icon: template,
        title: `Rating Templates`,
        path: `/raters/ratingtemplates`,
        permission: [
          `rating-templates:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ],
      },
      {
        icon: qouteForm,
        title: `Start a Quote`,
        path: `/raters/quoteform`,
        permission: [
          `quotes:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ],
      },
    ],
  },
  {
    icon: crm,
    title: `Public Quotes`,
    name: `crm`,
    submenu: [
      {
        icon: leads,
        title: `Leads`,
        path: `/crm/leads`,
        permission: [
          `leads:read`,
          `call-center-user:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ],
      },
      {
        icon: data,
        title: `Data Transactions`,
        path: `/crm/dataTransactions`,
        permission: [
          `data-transaction:read`,
          `call-center-user:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ],
      },
      {
        icon: carDealer,
        title: `Car Dealers`,
        path: `/crm/cardealers`,
        permission: [`car-dealer:read`],
      },
    ],
  },
  // {
  //   icon: carDealer,
  //   name: `CAR_DEALER`,
  //   title: `Agents`,
  //   path: `/admin/agents`,
  //   permission: [`car-dealer-manager:*`],
  // },
  {
    icon: leads,
    name: `CAR_DEALER`,
    title: `Leads`,
    path: `/crm/leads`,
    permission: [
      `car-dealer-agent:*`,
      `car-dealer-manager:*`,
    ],
  },
  {
    icon: rater,
    title: `Messenger`,
    name: `Messenger`,
    path: `/messenger`,
    permission: [
      `dashboard:read`,
      `messenger:read`,
      `corporate-manager:*`,
      `manager:*`,
      `admin:*`,
    ],
  },

  {
    icon: ticket,
    title: `Ticket`,
    name: `ticket`,
    submenu: [
      {
        icon: category,
        title: `Ticket Category`,
        path: `/tickets/category`,
        permission: [
          `ticket-category:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ],
      },
      {
        icon: generateTicket,
        title: `Tickets`,
        path: `/ticketscenter`,
        permission: [
          `ticket:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ],
      },
    ],
  },
  {
    icon: taskManager,
    title: `Task Manager`,
    name: `taskmanager`,
    path: `/taskmanager`,
    permission: [
      `task:read`,
      `task:*`,
      `corporate-manager:*`,
      `manager:*`,
      `admin:*`,
    ],
  },
  {
    icon: customers,
    title: `Other Customers`,
    name: `/other-customers`,
    path: `/other-customers`,
    permission: [
      `other-customers:read`,
      `corporate-manager:*`,
      `manager:*`,
      `admin:*`,
    ],
  },
]
